import {
    ICondition,
    IAddCondition,
    IAddConditionResponse
} from 'modules/procedures/interfaces/IConditions';

import { ApiRoutesEnum } from 'shared/enums/ApiRoutesEnum';

import { apiService } from './ApiService';

class ConditionService {
    async getConditions(): Promise<ICondition[]> {
        const serviceResponse = apiService.responseHandler(
            await apiService.get<ICondition[]>(
                `${ApiRoutesEnum.CONDITIONS}?pagination=false`
            )
        );

        return serviceResponse;
    }

    async getConditionsList(): Promise<ICondition[]> {
        const serviceResponse = apiService.responseHandler(
            await apiService.get<ICondition[]>(
                `${ApiRoutesEnum.CONDITIONS}?pagination=false`
            )
        );

        return serviceResponse;
    }

    async getSpecificCondition(conditionID: any): Promise<ICondition> {
        const serviceResponse = apiService.responseHandler(
            await apiService.get<ICondition>(
                `${ApiRoutesEnum.CONDITIONS}/${conditionID}`
            )
        );

        return serviceResponse;
    }

    async addCondition(formObj: IAddCondition): Promise<IAddConditionResponse> {
        const serviceResponse = apiService.responseHandler(
            await apiService.post<IAddConditionResponse, IAddCondition>(
                ApiRoutesEnum.CONDITIONS,
                formObj
            )
        );

        return serviceResponse;
    }

    async updateCondition(formObj: IAddCondition): Promise<IAddConditionResponse> {
        const serviceResponse = apiService.responseHandler(
            await apiService.put<IAddConditionResponse, IAddCondition>(
                `${ApiRoutesEnum.CONDITIONS}/${formObj.id}`,
                formObj
            )
        );

        return serviceResponse;
    }

    async deleteCondition(conditionID: number): Promise<any> {
        return await apiService.delete(
            `${ApiRoutesEnum.CONDITIONS}/${conditionID}`
        );
    }

    // async addCondition(formObj: IAddProcedure): Promise<IAddProcedureResponse> {
    //     const serviceResponse = apiService.responseHandler(
    //         await apiService.post<IAddProcedureResponse, IAddProcedure>(
    //             ApiRoutesEnum.CONDITIONS,
    //             formObj
    //         )
    //     );

    //     return serviceResponse;
    // }

    // async updateCondition(
    //     formObj: IAddProcedure
    // ): Promise<IAddProcedureResponse> {
    //     const serviceResponse = apiService.responseHandler(
    //         await apiService.put<IAddProcedureResponse, IAddProcedure>(
    //             `${ApiRoutesEnum.CONDITIONS}/${formObj.id}`,
    //             formObj
    //         )
    //     );

    //     return serviceResponse;
    // }

    // async deleteCondition(procedureID: number): Promise<any> {
    //     return await apiService.delete(
    //         `${ApiRoutesEnum.CONDITIONS}/${procedureID}`
    //     );
    // }
}

export const conditionService = new ConditionService();
