export enum ApiRoutesEnum {
    ME = '/me',
    TOKEN = '/token',
    REQUEST_PASSWORD_RESET = '/magic-links',
    USERS = '/users',
    EMPLOYEES = '/employees',

    PROCEDURES = '/procedures',

    CONDITIONS = '/conditions',

    ORGANIZATIONS = '/organizations', // Hospital System
    FACILITIES = '/facilities',
    MS_DRG_CODE = '/ms-diagnostic-related-groups',

    STANDARD_REPORT = '/standard-reports',
    STANDARD_REPORT_MONTHLY = '/standard-monthly-performance',
    SIGMA_REPORT = '/sigma-reports',
}
