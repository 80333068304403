import { useQuery, useQueryClient } from 'react-query';
import { ITableHeaders } from 'shared/components/Table/Table';
import { procedureService } from 'shared/services/ProcedureService';

interface IUseProcedures {
    organization: number;
    facility: number;
    code: number;
    headers: ITableHeaders[];
}

export const useProcedures = (params: IUseProcedures) => {
    const queryClient = useQueryClient();

    const { data, isLoading, isError, refetch } = useQuery(
        [
            'procedure',
            params.code,
            params.facility,
            params.organization,
            `${params.headers.filter((header) => header.selected)[0]?.title}=${
                params.headers.filter((header) => header.selected)[0]?.sortable
            }`,
        ],
        () => {
            let queryParams: any = {
                'order[facility.organization.name]': 'asc',
            };

            params.headers.forEach((header) => {
                if (header.selected) {
                    switch (header.title) {
                        case 'Hospital System':
                            queryParams = {
                                'order[facility.organization.name]':
                                    header.sortable,
                            };
                            break;
                        case 'Hospital Site':
                            queryParams = {
                                'order[facility.name]': header.sortable,
                            };
                            break;
                        case 'MS DRG Code':
                            queryParams = {
                                'order[ms_diagnostic_related_group.code]':
                                    header.sortable,
                            };
                            break;

                        default:
                            break;
                    }
                }
            });

            if (params?.organization) {
                queryParams['facility.organization.id'] = params.organization;
            }
            if (params?.facility) {
                queryParams['facility.id'] = params.facility;
            }
            if (params?.code) {
                queryParams['condition.id'] = params.code;
            }

            return procedureService.getProceduresCollection(queryParams);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting student - ', error);
            },
            initialData: () =>
                queryClient.getQueryData([
                    'procedure',
                    params.code,
                    params.facility,
                    params.organization,
                    `${
                        params.headers.filter((header) => header.selected)[0]
                            ?.title
                    }=${
                        params.headers.filter((header) => header.selected)[0]
                            ?.sortable
                    }`,
                ]),
            // staleTime: TimeDurationEnum.HOUR,
            // cacheTime: 0,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
