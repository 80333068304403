import styles from './styles.module.scss';

import ContentTitle from 'shared/components/ContentTitle/ContentTitle';

import ProceduresView from '../components/Procedures/ProceduresView/ProceduresView'
import { useState } from 'react';
import ConditionsView from '../components/Conditions/ConditionsView/ConditionsView';

export default function HospitalConditions() {
    const [screen, setScreen] = useState('procedures');

    return (
        <div>
            <div className={styles.ContentTitle}>
                <ContentTitle title="Hospital Conditions" />

                {/* <ExportButton title="Download" /> */}
            </div>

            <div className={styles.contentMenu}>
                <ul>
                    <li
                        className={screen === 'procedures' ? styles.active : null}
                        onClick={() => setScreen('procedures')}
                    >
                        Hospital Conditions
                    </li>
                    <li
                        className={screen === 'monthly' ? styles.active : null}
                        onClick={() => setScreen('monthly')}
                    >
                        Conditions
                    </li>
                </ul>
            </div>

            {screen === 'procedures' ? <ProceduresView /> : <ConditionsView />}
        </div>
    );
}
