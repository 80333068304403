import { useUser } from 'modules/layout/hooks/useUser';
import React, { useEffect, useState } from 'react';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import UsersTable from '../components/UsersTable/UsersTable';

import Button from 'shared/components/Button/Button';
import filterStyle from '../../../styles/filter.style.module.scss';
import styles from './styles.module.scss';


export default function UserManagement() {
    const [headers, setHeaders] = useState<ITableHeaders[]>([]);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [searchVar, setSearchVar] = useState(0);
    const [searchText, setSearchText] = useState("");

    const { user } = useUser();

    // const [lastName, setLastName] = useState("");

    useEffect(() => {
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
                setHeaders([
                    { title: 'User ID', sortable: 'asc' },
                    { title: 'First name', sortable: 'asc' },
                    { title: 'Last name', sortable: 'asc' },
                    { title: 'Email' },
                    { title: 'Role' },
                ]);

                break;

            default:
                break;
        }
    }, []);

    const orderByTableHeader = (index: number) => {
        let headerCopy = [...headers];

        // set headers to default
        headerCopy = headerCopy.map((header) => ({
            ...header,
            selected: false,
        }));

        // toggle selected header
        headerCopy[index] = {
            ...headerCopy[index],
            selected: true,
            sortable: headerCopy[index].sortable === 'asc' ? 'desc' : 'asc',
        };

        setHeaders(headerCopy);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
          setHeaders(headers);
        }
      };

    const handleSearchClick = () => {
        setShouldRefetch(true);
    };

    const handleRefetchComplete = () => {
        setShouldRefetch(false);
    }

    return (
        <div>
            <div className={styles.ContentTitle}>
                <ContentTitle title="User management" />
            </div>
            <div className={filterStyle.searchFilterBox}>
                <div className={filterStyle.filters}>
                    <div className={filterStyle.filterInput1}>
                        <div className={styles.SearchTitle}>
                            Search
                        </div>
                    </div>
                    <div className={filterStyle.filterInput}>
                        <select
                            name=""
                            id=""
                            onChange={(e)=>{setSearchVar(+e.target.value)}}
                            value={searchVar}
                            className={styles.SelectSearch}
                        >
                            <option value={0}>Last Name</option>
                            <option value={1}>Mail Address</option>   
                            <option value={2}>Role</option>       
                        </select>
                    </div>
                    <div className={filterStyle.filterInput}>
                        <input
                          type="text"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={handleKeyPress}
                          className={styles.InputSearch}
                        />
                    </div>
                </div>
                <div className={styles.ButtonsPlace}>
                    <Button
                        title="Clear"
                        onClick={() => {
                            setSearchVar(0);
                            setSearchText("");
                            handleSearchClick();
                        }}
                        className={styles.Button}
                    />
                    <Button
                        title="Search"
                        onClick={() =>
                            handleSearchClick()
                        }
                        className={styles.Button}
                        fill
                    />
                </div>
            </div>

            <UsersTable
                headers={headers}
                orderByTableHeader={orderByTableHeader}
                searchVar={searchVar}
                searchText={searchText}
                shouldRefetch={shouldRefetch}
                onRefetchComplete={handleRefetchComplete}
            />
        </div>
    );
}
