import { useMutation } from 'react-query';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { conditionService } from 'shared/services/ConditionService';

export const useDeleteCondition = () => {
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (conditionID: number) => {
            return conditionService.deleteCondition(conditionID);
        },
        {
            onSuccess: () => {
                showToast('success', 'Condition deleted');
            },
            onError: (error: any) => {
                console.log('Error on posting condition - ');
                showToast('error', error?.detail);
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        serverErrorMessage: isError,
    };
};
