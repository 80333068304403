import { useQuery, useQueryClient } from 'react-query';
import { conditionService } from 'shared/services/ConditionService';

export const useConditionsList = () => {
    const queryClient = useQueryClient();
    const { data, isLoading, isError, refetch } = useQuery(
        ['condition'],
        () => conditionService.getConditionsList(),
        {
            onError: (error: Error) => {
                console.log('Error on getting Conditions - ', error);
            },
            initialData: () => queryClient.getQueryData(['Conditions']),
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
