import { useUser } from 'modules/layout/hooks/useUser';
import { useStandardReport } from 'modules/standardReport/hooks/useStandardReport';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ExportButton from 'shared/components/Button/ExportButton';
import Loading from 'shared/components/Loading/Loading';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { dollarConverter } from 'shared/utils/currencyFormater';

import styles from './styles.module.scss';

interface IStandardReportProps {
    searchParams: {
        organization: number;
        facility: number;
        code: number;
        year: number;
        month: number;
        physician: number;
    };
    headers: ITableHeaders[];
    orderByTableHeader?: (index: number) => void;
}

export default function StandardReportTable({
    searchParams,
    headers,
    orderByTableHeader,
}: IStandardReportProps) {
    const [tableData, setTableData] = useState([]);

    const { user } = useUser();

    const {
        data: StandardReportData,
        isLoading,
        refetch,
    } = useStandardReport({
        ...searchParams,
        headers,
    });

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (tableData.length) {
            headers.forEach((header) => {
                if (header.selected) {
                    refetch(); // refetch when header change
                }
            });
        }
    }, [headers]);

    useEffect(() => {
        // console.log('effect', ProceduresData);
        const tempTableData: any = [];
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
            case UserRoleEnum.ROLE_CARE_OPERATOR:
                StandardReportData?.forEach((report) => {
                    // console.log('[PROCEDURE]: ', procedure);
                    tempTableData.push({
                        data: [
                            report.year,
                            moment(report.month, 'MM').format('MMMM'),
                            report.user.facilities[0].organization.name,
                            report.user.facilities[0].name,
                            report.user.full_name, // fix later
                            report.procedure.condition.name,
                            // report.procedure.ms_diagnostic_related_group.code,
                            report.discharges,
                            report.alerts,
                            report.readmits,
                            report.readmits_avoided,
                            dollarConverter.format(
                                report.readmits_cost_avoided
                            ),
                            dollarConverter.format(report.readmits_cost),
                            dollarConverter.format(report.cumulative_losses),
                            (
                                report.procedure.historical_baseline * 100
                            ).toFixed(2) + '%',
                        ],
                        // actions: {
                        //     onClick: () =>
                        //         rowClickHandler(procedure.id.toString()),
                        // },
                    });
                });

                setTableData(tempTableData);
                break;

            default:
                break;
        }
    }, [StandardReportData]);

    return (
        <>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className={styles.ButtonsPlace}>
                        <ExportButton
                            title="Export"
                            exportData={tableData}
                            headers={headers}
                            fileName="Standard_Report"
                            style={styles.Button}
                        />
                    </div>
                    <Table
                        data={{
                            headers,
                            row: tableData,
                        }}
                        orderByTableHeader={orderByTableHeader}
                    />
                </>
            )}
        </>
    );
}
