import axios, { CancelTokenSource } from "axios";

const CancelToken = axios.CancelToken;

export interface ICancelTokenHandler {
    handleRequestCancellation: (endpoint: string) => CancelTokenSource;
}

export function createCancelTokenHandler(): ICancelTokenHandler {
    let cancelToken: CancelTokenSource = undefined;

    const cancelTokenHandler = {
        handleRequestCancellation: (endpoint: string) => {
            // if previous cancel token exists, cancel the request
            cancelToken && cancelToken.cancel(`Endpoint ${endpoint} canceled`);

            // create new cancel token
            cancelToken = CancelToken.source();

            return cancelToken;
        },
    };

    return cancelTokenHandler;
}
