import React, { useEffect, useState } from 'react';
import { ITableHeaders } from 'shared/components/Table/Table';

import ConditionsTable from '../ConditionsTable/ConditionsTable';

export default function ConditionsView() {

    const [headers, setHeaders] = useState<ITableHeaders[]>([]);

    useEffect(() => {
        setHeaders([
            { title: 'Condition ID' },
            { title: 'Condition' },
            { title: 'Description' },
        ]);
    }, []);

    return (
        <div>
            <ConditionsTable
                headers={headers}
            />
        </div>
    );
}
