import React, { useEffect } from 'react';
import * as Yup from 'yup';

import styles from './styles.module.scss';
import Button from 'shared/components/Button/Button';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import { FormInput } from 'shared/components/FormInputs/FormInputs';

import { useFormik } from 'formik';
import { useAddCondition } from 'modules/procedures/hooks/Conditions/useAddCondition';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import Loading from 'shared/components/Loading/Loading';
import { IAddCondition } from 'modules/procedures/interfaces/IConditions';
import { useSpecificCondition } from 'modules/procedures/hooks/Conditions/useSpecificCondition';
import { useUpdateCondition } from 'modules/procedures/hooks/Conditions/useUpdateCondition';

export default function NewCondition() {
    const { conditionID }: any = useParams();
    const isAddMode = !conditionID;

    const { push, goBack } = useHistory();

    const { data: ConditionDetails, isLoading: isDetailsLoading } =
        useSpecificCondition(conditionID);

    const { mutateAsync, isLoading } = useAddCondition();
    const {
        mutateAsync: updateCondition,
        isLoading: isLoadingUpdateProcedure,
    } = useUpdateCondition();

    const { handleSubmit, handleChange, values, errors, touched, setValues } =
        useFormik({
            initialValues: {
                name: '',
                description: '',
            },
            validationSchema: Yup.object({
                name: Yup.string().required('Name is required'),
                description: Yup.string().notRequired()
            }),
            onSubmit: async (values) => {
                const finalForm: IAddCondition = {
                    ...values,
                };

                if (isAddMode) {
                    if (await mutateAsync(finalForm)) {
                        push(ROUTES.PROCEDURES);
                    }
                } else {
                    await updateCondition({
                        ...finalForm,
                        id: ConditionDetails.id,
                    });
                    push(ROUTES.PROCEDURES);
                }
            },
        });

    useEffect(() => {
        if (!isAddMode && ConditionDetails) {
            setValues({
                name:ConditionDetails.name.toString(),
                description: ConditionDetails.description.toString(),
            });
        }
    }, [ConditionDetails]);

    return (
        <div>
            <ContentTitle
                title={`${isAddMode ? 'New Condition' : 'Edit Condition'}`}
            />

            {(!isAddMode &&
                isDetailsLoading) ? (
                <Loading height={300} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <FormInput
                        id="name"
                        label="Condition Name"
                        required
                        handleChange={handleChange}
                        value={values.name}
                        hasError={
                            touched.name &&
                            errors.name
                        }
                        errorMsg={errors.name}
                    />
                    <FormInput
                        id="description"
                        label="Condition Description"
                        handleChange={handleChange}
                        value={values.description}
                        hasError={touched.description && errors.description}
                        errorMsg={errors.description}
                    />

                    <div className={styles.FormButtons}>
                        <Button
                            title="Submit"
                            fill
                            type="submit"
                            loading={isLoading || isLoadingUpdateProcedure}
                        />
                        <Button title="Cancel" onClick={goBack} />
                    </div>
                </form>
            )}
        </div>
    );
}
