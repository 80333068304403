import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useDeleteCondition } from 'modules/procedures/hooks/Conditions/useDeleteCondition';
import { useSpecificCondition } from 'modules/procedures/hooks/Conditions/useSpecificCondition';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'shared/components/Button/Button';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import Loading from 'shared/components/Loading/Loading';
import Modal from 'shared/components/Modal/Modal';
import useModal from 'shared/hooks/useModal';
import { pathBuilder } from 'shared/utils/pathBuilder';

import styles from './styles.module.scss';


export default function ConditionsDetails() {
    const { conditionID }: any = useParams();

    const { push, goBack } = useHistory();
    const { data: condition, isLoading } = useSpecificCondition(conditionID);
    const { mutateAsync, isLoading: isDeleteLodaing } = useDeleteCondition();

    const { isOpen, closeModal, openModal } = useModal();

    return (
        <>
            <div className={styles.ContentTitle}>
                <ContentTitle title="Condition" />
                <div className={styles.ButtonsPlace}>
                    <Button
                        title="Edit"
                        className={styles.Button}
                        fill
                        onClick={() =>
                            push(
                                pathBuilder(
                                    ROUTES.CONDITIONS_EDIT,
                                    ':conditionID',
                                    conditionID
                                )
                            )
                        }
                    />
                    <Button
                        title="Go back"
                        className={styles.Button}
                        onClick={goBack}
                    />
                    <Button
                        title="Delete"
                        isDelete
                        className={styles.Button}
                        onClick={openModal}
                    />
                </div>
            </div>
            {isLoading ? (
                <Loading height={400} />
            ) : (
                <div className={styles.procedureDetailsBox}>
                    <div className={styles.Row} style={{ marginTop: '20px' }}>
                        <span className={styles.RowTitle}>Condition Name</span>
                        <span className={styles.RowValue}>
                            {condition.name}
                        </span>
                    </div>
                    <div className={styles.Row}>
                        <span className={styles.RowTitle}>Description</span>
                        <span className={styles.RowValue}>
                            {condition.description}
                        </span>
                    </div>
                </div>
            )}

            {isOpen ? (
                <Modal
                    title="Are you sure ?"
                    close={closeModal}
                    render={() => (
                        <div className={styles.ModalContent}>
                            <span>
                                You will delete procedure {condition.name}.
                            </span>
                            <Button
                                title="Delete"
                                className={styles.Button}
                                isDelete
                                fill
                                onClick={async () => {
                                    if (await mutateAsync(condition.id)) {
                                        closeModal();
                                        goBack();
                                    }
                                }}
                                loading={isDeleteLodaing}
                            />
                        </div>
                    )}
                />
            ) : null}
        </>
    );
}
