import { useUser } from 'modules/layout/hooks/useUser';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useUsersList } from 'modules/UserManagement/hooks/useUsersList';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from 'shared/components/Loading/Loading';
import Button from 'shared/components/Button/Button';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { pathBuilder } from 'shared/utils/pathBuilder';
import ExportButton from 'shared/components/Button/ExportButton';

import styles from './styles.module.scss';

interface IUsersTable {
    headers: ITableHeaders[];
    orderByTableHeader?: (index: number) => void;
    searchVar?: any;
    searchText?: string;
    shouldRefetch?: boolean;
    onRefetchComplete?: () => void;
}

export default function UsersTable({
    headers,
    orderByTableHeader,
    searchVar,
    searchText,
    shouldRefetch,
    onRefetchComplete
}: IUsersTable) {
    const { push } = useHistory();

    const [tableData, setTableData] = useState([]);

    const { data: UsersList, isLoading, refetch } = useUsersList({ headers });
    const { user } = useUser();

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (tableData.length) {
            headers.forEach((header) => {
                if (header.selected) {
                    refetch(); // refetch when header change
                }
            });
        }
    }, [headers]);

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (shouldRefetch) {
            refetch();
            onRefetchComplete();
            }
    }, [shouldRefetch]);

    useEffect(() => {
        // console.log('effect', ProceduresData);
        let tempTableData: any = [];
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
                UsersList?.forEach((user) => {
                    // console.log('[PROCEDURE]: ', procedure);
                    tempTableData.push({
                        data: [
                            user.id.toString(),
                            user.first_name,
                            user.last_name,
                            user.email,
                            user.roles[0] === UserRoleEnum.ROLE_ZILIUS_ADMIN
                                ? 'ZILIUS ADMIN'
                                : user.roles[0] ===
                                  UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER
                                ? 'ORGANIZATION QUALITY OFFICER'
                                : user.roles[0] ===
                                  UserRoleEnum.ROLE_CARE_OPERATOR
                                ? 'CARE OPERATOR'
                                : 'REGULAR',
                        ],
                        actions: {
                            onClick: () => rowClickHandler(user.id.toString()),
                        },
                    });
                });

                if(searchText){
                    tempTableData = searchHandler(tempTableData, searchVar, searchText)
                }

                setTableData(tempTableData);
                break;

            default:
                break;
        }
    }, [UsersList, shouldRefetch]);

    const rowClickHandler = (id: string) => {
        push(pathBuilder(ROUTES.ADMIN_DASHBOARD_EDIT, ':userID', id));
    };

    const searchHandler = (data: any[] = [], searchVar: number, searchText: string) => {
        if (searchVar===0){
            data = data.filter((entry) => entry["data"][2].toLowerCase().includes(searchText.toLowerCase()))
        }
        else if (searchVar===1){
            data = data.filter((entry) => entry["data"][3].toLowerCase().includes(searchText.toLowerCase()))
        }
        else if (searchVar===2){
            data = data.filter((entry) => entry["data"][4].toLowerCase().includes(searchText.toLowerCase()))
        }
        return data;
    };

    return (
        <div>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className={styles.ButtonsPlace}>
                        <Button
                            title="Add new user"
                            className={styles.Button}
                            onClick={() => push(ROUTES.ADMIN_DASHBOARD_NEW)}
                            fill
                        />
                        <ExportButton
                            title="Export"
                            exportData={tableData}
                            headers={headers}
                            fileName="Users"
                            style={styles.Button}
                        />
                    </div>
                <Table
                    data={{
                        headers,
                        row: tableData,
                    }}
                    orderByTableHeader={orderByTableHeader}
                />
                </>
            )}
        </div>
    );
}
