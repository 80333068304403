import { useQuery, useQueryClient } from 'react-query';
// import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
import { conditionService } from 'shared/services/ConditionService';

export const useSpecificCondition = (conditionID: any) => {
    const queryClient = useQueryClient();
    const { data, isLoading, isError, refetch } = useQuery(
        ['condition', conditionID],
        () => {
            return conditionService.getSpecificCondition(conditionID);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting condition - ', error);
            },
            initialData: () =>
                queryClient.getQueryData(['condition', conditionID]),
            enabled: Boolean(conditionID),
            // staleTime: TimeDurationEnum.MINUTE,
            // cacheTime: 0,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
