import { useUser } from 'modules/layout/hooks/useUser';
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/Button/Button';
import Loading from 'shared/components/Loading/Loading';
import { ITableHeaders } from 'shared/components/Table/Table';
import { ICondition } from 'modules/procedures/interfaces/IConditions';

import { UserRoleEnum } from 'shared/enums/UserRoleEnum';

import { useFacilities } from 'shared/hooks/Filters/useFacilities';
import { useConditionsList } from 'shared/hooks/Filters/useConditionLists';
import { useOrganizations } from 'shared/hooks/Filters/useOrganizations';

import filterStyle from '../../../../styles/filter.style.module.scss';
import StandardReportTableMonthly from '../standardReportTable/StandardReportTableMonthly';
import styles from './styles.module.scss';

export default function MonthlyPerformance() {
    const [searchParams, setSearchParams] = useState<any>({
        year: 0,
        organization: 0,
        facility: 0,
        code: 0,
    });
    const [year, setYear] = useState(0);
    const [selectedOrganization, setSelectedOrganization] = useState(0);
    const [selectedFacility, setSelectedFacility] = useState(0);
    const [selectedCode, setSelectedCode] = useState(0);

    const { user } = useUser();

    const [headers, setHeaders] = useState<ITableHeaders[]>([]);

    const { data: organizationList, isLoading: isOrganizationListLoading } =
        useOrganizations();

    const { data: facilitiesList, isLoading: isFacilitiesListLoading } =
        useFacilities(selectedOrganization);

    const { data: conditionsList, isLoading: isConditionsListLoading } =
        useConditionsList();

    useEffect(() => {
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
            case UserRoleEnum.ROLE_CARE_OPERATOR:
                setHeaders([
                    { title: '' },
                    { title: 'January' },
                    { title: 'February' },
                    { title: 'March' },
                    { title: 'April' },
                    { title: 'May' },
                    { title: 'June' },
                    { title: 'July' },
                    { title: 'August' },
                    { title: 'September' },
                    { title: 'October' },
                    { title: 'November' },
                    { title: 'December' },
                ]);

                break;

            default:
                break;
        }
    }, []);

    const orderByTableHeader = (index: number) => {
        let headerCopy = [...headers];

        // set headers to default
        headerCopy = headerCopy.map((header) => ({
            ...header,
            selected: false,
        }));

        // toggle selected header
        headerCopy[index] = {
            ...headerCopy[index],
            selected: true,
            sortable: headerCopy[index].sortable === 'asc' ? 'desc' : 'asc',
        };

        setHeaders(headerCopy);
    };

    const sortConditionsList = (conditionsList: ICondition[]) => {
        const sortedConditionsList = conditionsList.sort(function (a,b) {
            return a.name.localeCompare(b.name);
        })
        return sortedConditionsList;
    }

    return (
        <>
            <div className={filterStyle.searchFilterBox}>
                <div className={filterStyle.filters}>
                    <div className={filterStyle.filterInput}>
                        <span>Year</span>
                        <select
                            name=""
                            id=""
                            onChange={(e) => setYear(+e.target.value)}
                            value={year}
                        >
                            <option value={0}>All</option>
                            <option value="2022">2022</option>
                        </select>
                    </div>

                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ? (
                        <div className={filterStyle.filterInput}>
                            {isOrganizationListLoading ? (
                                <Loading height={60} />
                            ) : (
                                <>
                                    <span>Hospital System</span>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) => {
                                            setSelectedOrganization(
                                                +e.target.value
                                            );
                                            setSelectedFacility(0);
                                        }}
                                        value={selectedOrganization}
                                    >
                                        <option value={0}>All</option>
                                        {organizationList?.items.map(
                                            (organization) => (
                                                <option
                                                    key={organization.id}
                                                    value={organization.id}
                                                >
                                                    {organization.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    ) : null}
                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ||
                    user.role ===
                        UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER ? (
                        <div className={filterStyle.filterInput}>
                            {isFacilitiesListLoading ? (
                                <Loading height={60} />
                            ) : (
                                <>
                                    <span>Hospital Site</span>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) =>
                                            setSelectedFacility(+e.target.value)
                                        }
                                        value={selectedFacility}
                                        disabled={
                                            user.role ===
                                            UserRoleEnum.ROLE_ZILIUS_ADMIN
                                                ? selectedOrganization === 0
                                                : false
                                        }
                                    >
                                        <option value={0}>All</option>
                                        {facilitiesList?.items.map(
                                            (facility) => (
                                                <option
                                                    key={facility.id}
                                                    value={facility.id}
                                                >
                                                    {facility.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    ) : null}

                    {/* <div className={filterStyle.filterInput}>
                        {isMSDRGCodesListLoading ? (
                            <Loading height={60} />
                        ) : (
                            <>
                                <span>MS DRG Code</span>
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                        setSelectedCode(+e.target.value)
                                    }
                                    value={selectedCode}
                                >
                                    <option value={0}>All</option>
                                    {MSDRGCodesList?.items.map((code) => (
                                        <option key={code.id} value={code.id}>
                                            {code.code} - {code.description}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div> */}
                                        <div className={filterStyle.filterInput}>
                        {isConditionsListLoading ? (
                            <Loading height={60} />
                        ) : (
                            <>
                                <span>Condition</span>
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                        setSelectedCode(+e.target.value)
                                    }
                                    value={selectedCode}
                                >
                                    <option value={0}>All</option>
                                    {sortConditionsList(conditionsList)?.map((code) => (
                                        <option key={code.id} value={code.id}>
                                            {code.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>
                </div>

                <div>
                    <Button
                        title="Clear"
                        onClick={() => {
                            setYear(0);
                            setSelectedOrganization(0);
                            setSelectedFacility(0);
                            setSelectedCode(0);
                            setSearchParams({
                                year: 0,
                                organization: 0,
                                facility: 0,
                                code: 0,
                            });
                        }}
                        className={styles.SeachButton}
                    />
                    <Button
                        title="Search"
                        onClick={() =>
                            setSearchParams({
                                year,
                                organization: selectedOrganization,
                                facility: selectedFacility,
                                code: selectedCode,
                            })
                        }
                        className={styles.SeachButton}
                        fill
                    />
                </div>
            </div>
            <StandardReportTableMonthly
                searchParams={searchParams}
                headers={headers}
                orderByTableHeader={orderByTableHeader}
            />
        </>
    );
}
