import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useDeleteProcedure } from 'modules/procedures/hooks/Procedures/useDeleteProcedure';
import { useProcedure } from 'modules/procedures/hooks/Procedures/useUprocedure';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'shared/components/Button/Button';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import Loading from 'shared/components/Loading/Loading';
import Modal from 'shared/components/Modal/Modal';
import useModal from 'shared/hooks/useModal';
import { pathBuilder } from 'shared/utils/pathBuilder';

import styles from './styles.module.scss';

export default function ProcedureDetails() {
    const { procedureID }: any = useParams();

    const { push, goBack } = useHistory();
    const { data: procedure, isLoading } = useProcedure(procedureID);
    const { mutateAsync, isLoading: isDeleteLodaing } = useDeleteProcedure();

    const { isOpen, closeModal, openModal } = useModal();

    return (
        <>
            <div className={styles.ContentTitle}>
                <ContentTitle title="Hospital Condition" />
                <div className={styles.ButtonsPlace}>
                    <Button
                        title="Edit"
                        className={styles.Button}
                        fill
                        onClick={() =>
                            push(
                                pathBuilder(
                                    ROUTES.PROCEDURES_EDIT,
                                    ':procedureID',
                                    procedureID
                                )
                            )
                        }
                    />
                    <Button
                        title="Go back"
                        className={styles.Button}
                        onClick={goBack}
                    />
                    <Button
                        title="Delete"
                        isDelete
                        className={styles.Button}
                        onClick={openModal}
                    />
                </div>
            </div>
            {isLoading ? (
                <Loading height={400} />
            ) : (
                <div className={styles.procedureDetailsBox}>
                    <div className={styles.Row} style={{ marginTop: '20px' }}>
                        <span className={styles.RowTitle}>Hospital System</span>
                        <span className={styles.RowValue}>
                            {procedure.facility.organization.name}
                        </span>
                    </div>
                    <div className={styles.Row}>
                        <span className={styles.RowTitle}>Hospital Site</span>
                        <span className={styles.RowValue}>
                            {procedure.facility.name}
                        </span>
                    </div>
                    <div className={styles.Row}>
                        <span className={styles.RowTitle}>Condition</span>
                        <span className={styles.RowValue}>
                            {procedure.condition.name}
                        </span>
                    </div>
                    {/* <div className={styles.Row}>
                        <span className={styles.RowTitle}>MS DRG Code</span>
                        <span className={styles.RowValue}>
                            {procedure.condition.name}
                        </span>
                    </div>
                    <div className={styles.Row}>
                        <span className={styles.RowTitle}>
                            MS DRG Description
                        </span>
                        <span className={styles.RowValue}>
                            {procedure.condition.description}
                        </span>
                    </div> */}
                    <div className={styles.Row}>
                        <span className={styles.RowTitle}>
                            Historical Baseline %
                        </span>
                        <span className={styles.RowValue}>
                            {(procedure.historical_baseline * 100).toFixed(2)} %
                        </span>
                    </div>
                    <div className={styles.Row}>
                        <span className={styles.RowTitle}>
                            Procedure Cost $
                        </span>
                        <span className={styles.RowValue}>
                            {procedure.cost.toFixed(2)}
                        </span>
                    </div>
                </div>
            )}

            {isOpen ? (
                <Modal
                    title="Are you sure ?"
                    close={closeModal}
                    render={() => (
                        <div className={styles.ModalContent}>
                            <span>
                                You will delete procedure {procedure.code}.
                            </span>
                            <Button
                                title="Delete"
                                className={styles.Button}
                                isDelete
                                fill
                                onClick={async () => {
                                    if (await mutateAsync(procedure.id)) {
                                        closeModal();
                                        goBack();
                                    }
                                }}
                                loading={isDeleteLodaing}
                            />
                        </div>
                    )}
                />
            ) : null}
        </>
    );
}
