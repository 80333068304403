import { useMutation } from 'react-query';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { procedureService } from 'shared/services/ProcedureService';
import { IAddProcedure } from '../../interfaces/IProcedure';

export const useUpdateProcedure = () => {
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (procedureForm: IAddProcedure) => {
            return procedureService.updateProcedure(procedureForm);
        },
        {
            onSuccess: () => {
                showToast('success', 'Procedure updated');
            },
            onError: (error: any) => {
                console.log('Error on updating procedure - ');
                showToast('error', error?.detail);
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        serverErrorMessage: isError,
    };
};
