import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useConditions } from 'modules/procedures/hooks/Conditions/useConditions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'shared/components/Button/Button';
import Loading from 'shared/components/Loading/Loading';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { pathBuilder } from 'shared/utils/pathBuilder';

import styles from './styles.module.scss';

interface IConditionsTable {
    headers: ITableHeaders[];
}

export default function ConditionsTable({
    headers,
}: IConditionsTable) {
    const [tableData, setTableData] = useState([]);

    const { push } = useHistory();

    const {
        data: ConditionsData,
        isLoading,
    } = useConditions({ headers });

    // useEffect(() => {
    //     // refetch procedure with sorting order from table headers
    //     // happens only when user click it
    //     if (tableData.length) {
    //         headers.forEach((header) => {
    //             if (header.selected) {
    //                 refetch(); // refetch when header change
    //             }
    //         });
    //     }
    // }, [headers]);

    useEffect(() => {
        const tempTableData: any = [];
            ConditionsData?.forEach((entry) => {
                tempTableData.push({
                    data: [
                        entry.id,
                        entry.name,
                        entry.description
                    ],
                    actions: {
                        onClick: () =>
                            rowClickHandler(entry.id.toString()),
                    },
                });
            });
            setTableData(tempTableData);
    }, [ConditionsData]);

    const rowClickHandler = (id: string) => {
        console.log('[ROW CLICK HANDLER]: ', id);
        push(pathBuilder(ROUTES.CONDITIONS_DETAILS, ':conditionID', id));
    };

    return (
        <>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className={styles.ButtonsPlace}>
                        <Button
                            title="New Condition"
                            className={styles.Button}
                            onClick={() => push(ROUTES.CONDITIONS_NEW)}
                            fill
                        />
                    </div>
                    <Table
                        data={{
                            headers,
                            row: tableData,
                        }}
                    />
                </>
            )}
        </>
    );
}
