import { useMutation } from 'react-query';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { userService } from 'shared/services/UserService';
import { IResetPasswordCredentials } from '../interfaces/ISignInCredentials';

export const useResetPasswordRequest = () => {
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (credentials: IResetPasswordCredentials) => {
            return userService.requestPasswordReset(credentials);
        },

        {
            onSuccess: () => {
                showToast('success', 'Email sent, check you email! (sometimes it goes to spam, make sure to mark that it is not spam)');
            },

            onError: (error: any) => {
                showToast('error', error?.detail);
                console.log('Error on reset password request - ', error);
            },
        }
    );

    return {
        requestPasswordReset: mutateAsync,
        isLoading,
        error: {
            isError,
        },
    };
};
