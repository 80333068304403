import { useUser } from 'modules/layout/hooks/useUser';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useProcedures } from 'modules/procedures/hooks/Procedures/useProcedures';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'shared/components/Button/Button';
import ExportButton from 'shared/components/Button/ExportButton';
import Loading from 'shared/components/Loading/Loading';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { pathBuilder } from 'shared/utils/pathBuilder';

import styles from './styles.module.scss';

interface IProceduresTable {
    searchParams: { organization: number; facility: number; code: number };
    headers: ITableHeaders[];
    orderByTableHeader?: (index: number) => void;
}

export default function ProceduresTable({
    searchParams,
    headers,
    orderByTableHeader,
}: IProceduresTable) {
    const [tableData, setTableData] = useState([]);

    const { push } = useHistory();

    const { user } = useUser();

    const {
        data: ProceduresData,
        isLoading,
        refetch,
    } = useProcedures({ ...searchParams, headers });

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (tableData.length) {
            headers.forEach((header) => {
                if (header.selected) {
                    refetch(); // refetch when header change
                }
            });
        }
    }, [headers]);

    useEffect(() => {
        // console.log('effect', ProceduresData);
        const tempTableData: any = [];
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
                ProceduresData?.forEach((procedure) => {
                    // console.log('[PROCEDURE]: ', procedure);
                    tempTableData.push({
                        data: [
                            procedure.id.toString(),
                            procedure.facility.organization.name,
                            procedure.facility.name,
                            procedure.condition.name,
                            // procedure.ms_diagnostic_related_group.code,
                            // procedure.ms_diagnostic_related_group.description,
                        ],
                        actions: {
                            onClick: () =>
                                rowClickHandler(procedure.id.toString()),
                        },
                    });
                });

                setTableData(tempTableData);
                break;

            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
                ProceduresData?.forEach((procedure) => {
                    // console.log('[PROCEDURE]: ', procedure);
                    tempTableData.push({
                        data: [
                            procedure.id.toString(),
                            procedure.facility.name,
                            procedure.condition.name,
                            // procedure.ms_diagnostic_related_group.code,
                            // procedure.ms_diagnostic_related_group.description,
                            `${(
                                procedure.historical_baseline * 100
                            ).toFixed()} %`,
                        ],
                        actions: {
                            onClick: () =>
                                rowClickHandler(procedure.id.toString()),
                        },
                    });
                });

                setTableData(tempTableData);
                break;

            case UserRoleEnum.ROLE_CARE_OPERATOR:
                ProceduresData?.forEach((procedure) => {
                    // console.log('[PROCEDURE]: ', procedure);
                    tempTableData.push({
                        data: [
                            procedure.id.toString(),
                            procedure.condition.name,
                            // procedure.ms_diagnostic_related_group.code,
                            // procedure.ms_diagnostic_related_group.description,
                            `${(
                                procedure.historical_baseline * 100
                            ).toFixed()} %`,
                            `${procedure.cost.toFixed()} $`,
                        ],
                        actions: {
                            onClick: () =>
                                rowClickHandler(procedure.id.toString()),
                        },
                    });
                });

                setTableData(tempTableData);
                break;
            default:
                break;
        }
    }, [ProceduresData]);

    const rowClickHandler = (id: string) => {
        console.log('[ROW CLICK HANDLER]: ', id);
        push(pathBuilder(ROUTES.PROCEDURES_DETAILS, ':procedureID', id));
    };

    return (
        <>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className={styles.ButtonsPlace}>
                        <Button
                            title="New Hospital Condition"
                            className={styles.Button}
                            onClick={() => push(ROUTES.PROCEDURES_NEW)}
                            fill
                        />
                        <ExportButton
                            title="Export"
                            exportData={tableData}
                            headers={headers}
                            fileName="Procedure"
                            style={styles.Button}
                        />
                    </div>
                    <Table
                        data={{
                            headers,
                            row: tableData,
                        }}
                        orderByTableHeader={orderByTableHeader}
                    />
                </>
            )}
        </>
    );
}
