import { FC } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "index";

export const ReactQueryClientProvider: FC = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <>
                {children}
                <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                />
            </>
        </QueryClientProvider>
    );
}
