import { useMutation } from 'react-query';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { conditionService } from 'shared/services/ConditionService';
import { IAddCondition } from '../../interfaces/IConditions';

export const useAddCondition = () => {
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (conditionForm: IAddCondition) => {
            return conditionService.addCondition(conditionForm);
        },
        {
            onSuccess: () => {
                showToast('success', 'Condition added');
            },
            onError: (error: any) => {
                console.log('Error on posting condition - ');
                showToast('error', error?.detail);
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        serverErrorMessage: isError,
    };
};
