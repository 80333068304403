import { useQuery, useQueryClient } from 'react-query';
import { ITableHeaders } from 'shared/components/Table/Table';
import { conditionService } from 'shared/services/ConditionService';

interface IUseConditions {
    headers: ITableHeaders[];
}

export const useConditions = (params: IUseConditions) => {
    const queryClient = useQueryClient();

    const { data, isLoading, isError, refetch } = useQuery(
        [
            'conditions',
            `${params.headers.filter((header) => header.selected)[0]?.title}=${
                params.headers.filter((header) => header.selected)[0]?.sortable
            }`,
        ],
        () => {

            return conditionService.getConditions();
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting conditions -', error);
            },
            initialData: () =>
                queryClient.getQueryData([
                    'condition',
                    `${
                        params.headers.filter((header) => header.selected)[0]
                            ?.title
                    }=${
                        params.headers.filter((header) => header.selected)[0]
                            ?.sortable
                    }`,
                ]),
            // staleTime: TimeDurationEnum.HOUR,
            // cacheTime: 0,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
