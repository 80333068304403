import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import filterStyle from '../../../../../styles/filter.style.module.scss';

import Button from 'shared/components/Button/Button';
import { ITableHeaders } from 'shared/components/Table/Table';
import { useUser } from 'modules/layout/hooks/useUser';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import Loading from 'shared/components/Loading/Loading';
import { useOrganizations } from 'shared/hooks/Filters/useOrganizations';
import { useFacilities } from 'shared/hooks/Filters/useFacilities';
import { useConditionsList } from 'shared/hooks/Filters/useConditionLists';
import ProceduresTable from '../ProceduresTable/ProceduresTable';
import { ICondition } from 'modules/procedures/interfaces/IConditions';

export default function ProceduresView() {
    const [searchParams, setSearchParams] = useState({
        organization: 0,
        facility: 0,
        code: 0,
    });
    const [selectedOrganization, setSelectedOrganization] = useState(0);
    const [selectedFacility, setSelectedFacility] = useState(0);
    const [selectedCode, setSelectedCode] = useState(0);

    const [headers, setHeaders] = useState<ITableHeaders[]>([]);

    const { user } = useUser();

    const { data: organizationList, isLoading: isOrganizationListLoading } =
        useOrganizations();

    const { data: facilitiesList, isLoading: isFacilitiesListLoading } =
        useFacilities(selectedOrganization);

    const { data: conditionsList, isLoading: isConditionsListLoading } =
        useConditionsList();

    useEffect(() => {
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
                setHeaders([
                    { title: 'Procedure ID' },
                    { title: 'Hospital System', sortable: 'asc' },
                    { title: 'Hospital Site', sortable: 'asc' },
                    { title: 'Condition' },
                    // { title: 'MS DRG Code', sortable: 'asc' },
                    // { title: 'MS DRG Description' },
                ]);

                break;
            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
                setHeaders([
                    { title: 'Procedure ID' },
                    { title: 'Hospital Site', sortable: 'asc' },
                    { title: 'Condition' },
                    // { title: 'MS DRG Code', sortable: 'asc' },
                    // { title: 'MS DRG Description' },
                    { title: 'Historical Baseline %' },
                ]);

                break;

            case UserRoleEnum.ROLE_CARE_OPERATOR:
                setHeaders([
                    { title: 'Procedure ID' },
                    { title: 'Condition' },
                    // { title: 'MS DRG Code', sortable: 'asc' },
                    // { title: 'MS DRG Description' },
                    { title: 'Historical Baseline %' },
                    { title: 'Procedure Cost $' },
                ]);

                break;

            default:
                break;
        }
    }, []);

    const sortConditionsList = (conditionsList: ICondition[]) => {
        const sortedConditionsList = conditionsList.sort(function (a,b) {
            return a.name.localeCompare(b.name);
        })
        return sortedConditionsList;
    }

    const orderByTableHeader = (index: number) => {
        let headerCopy = [...headers];

        // set headers to default
        headerCopy = headerCopy.map((header) => ({
            ...header,
            selected: false,
        }));

        // toggle selected header
        headerCopy[index] = {
            ...headerCopy[index],
            selected: true,
            sortable: headerCopy[index].sortable === 'asc' ? 'desc' : 'asc',
        };

        setHeaders(headerCopy);
    };

    return (
        <div>
            <div className={filterStyle.searchFilterBox}>
                <div className={filterStyle.filters}>
                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ? (
                        <div className={filterStyle.filterInput}>
                            {isOrganizationListLoading ? (
                                <Loading height={60} />
                            ) : (
                                <>
                                    <span>Hospital System</span>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) => {
                                            setSelectedOrganization(
                                                +e.target.value
                                            );
                                            setSelectedFacility(0);
                                        }}
                                        value={selectedOrganization}
                                    >
                                        <option value={0}>All</option>
                                        {organizationList?.items.map(
                                            (organization) => (
                                                <option
                                                    key={organization.id}
                                                    value={organization.id}
                                                >
                                                    {organization.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    ) : null}
                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ||
                    user.role ===
                        UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER ? (
                        <div className={filterStyle.filterInput}>
                            {isFacilitiesListLoading ? (
                                <Loading height={60} />
                            ) : (
                                <>
                                    <span>Hospital Site</span>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) =>
                                            setSelectedFacility(+e.target.value)
                                        }
                                        value={selectedFacility}
                                        disabled={
                                            user.role ===
                                            UserRoleEnum.ROLE_ZILIUS_ADMIN
                                                ? selectedOrganization === 0
                                                : false
                                        }
                                    >
                                        <option value={0}>All</option>
                                        {facilitiesList?.items.map(
                                            (facility) => (
                                                <option
                                                    key={facility.id}
                                                    value={facility.id}
                                                >
                                                    {facility.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    ) : null}
                    {/* <div className={filterStyle.filterInput}>
                        {isMSDRGCodesListLoading ? (
                            <Loading height={60} />
                        ) : (
                            <>
                                <span>MS DRG Code</span>
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                        setSelectedCode(+e.target.value)
                                    }
                                    value={selectedCode}
                                >
                                    <option value={0}>All</option>
                                    {MSDRGCodesList?.items.map((code) => (
                                        <option key={code.id} value={code.id}>
                                            {code.code} - {code.description}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div> */}
                    <div className={filterStyle.filterInput}>
                        {isConditionsListLoading ? (
                            <Loading height={60} />
                        ) : (
                            <>
                                <span>Condition</span>
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                        setSelectedCode(+e.target.value)
                                    }
                                    value={selectedCode}
                                >
                                    <option value={0}>All</option>
                                    { sortConditionsList(conditionsList)?.map((code) => (
                                        <option key={code.id} value={code.id}>
                                            {code.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <Button
                        title="Clear"
                        onClick={() => {
                            setSelectedOrganization(0);
                            setSelectedFacility(0);
                            setSelectedCode(0);

                            setSearchParams({
                                organization: 0,
                                facility: 0,
                                code: 0,
                            });
                        }}
                        className={styles.SeachButton}
                    />
                    <Button
                        title="Search"
                        onClick={() =>
                            setSearchParams({
                                organization: selectedOrganization,
                                facility: selectedFacility,
                                code: selectedCode,
                            })
                        }
                        className={styles.SeachButton}
                        fill
                    />
                </div>
            </div>
            {isOrganizationListLoading || isConditionsListLoading ? (
                <Loading height={300} />
            ) : (
                <ProceduresTable
                    searchParams={searchParams}
                    headers={headers}
                    orderByTableHeader={orderByTableHeader}
                />
            )}
        </div>
    );
}

//     data: [
//         '2022',
//         'Jan',
//         'McLaren',
//         'MBR',
//         'Dr.Lee',
//         'AMI',
//         '207',
//         '40',
//         '3',
//     ],
//     actions: {
//         onClick: () => clickHandler,
//     },
