import React, { useEffect } from 'react';
import * as Yup from 'yup';

import styles from './styles.module.scss';
import Button from 'shared/components/Button/Button';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import { FormInput, FormSelect, FormSelectWithSearch } from 'shared/components/FormInputs/FormInputs';

import { useFormik } from 'formik';
import { useOrganizations } from 'shared/hooks/Filters/useOrganizations';
import { useFacilities } from 'shared/hooks/Filters/useFacilities';
import { useAddProcedure } from 'modules/procedures/hooks/Procedures/useAddProcedure';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import Loading from 'shared/components/Loading/Loading';
import { IAddProcedure } from 'modules/procedures/interfaces/IProcedure';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { useUser } from 'modules/layout/hooks/useUser';
import { useProcedure } from 'modules/procedures/hooks/Procedures/useUprocedure';
import { useUpdateProcedure } from 'modules/procedures/hooks/Procedures/useUpdateProcedure';
import { useConditionsList } from 'shared/hooks/Filters/useConditionLists';


export default function NewProcedure() {
    const { procedureID }: any = useParams();
    const isAddMode = !procedureID;

    const { push, goBack } = useHistory();

    const { user } = useUser();

    const { data: ProcedureDetails, isLoading: isDetailsLoading } =
        useProcedure(procedureID);

    const { mutateAsync, isLoading } = useAddProcedure();
    const {
        mutateAsync: updateProcedure,
        isLoading: isLoadingUpdateProcedure,
    } = useUpdateProcedure();

    const { handleSubmit, handleChange, values, errors, touched, setValues } =
        useFormik({
            initialValues: {
                organization: '',
                facility: '',
                //code: '',
                // ms_diagnostic_related_group: '',
                condition: '',
                historical_baseline: '',
                cost: '',
            },
            validationSchema: Yup.object({
                facility: Yup.string().required('Hospital Site is required'),
                //code: Yup.string().required('Diagnosis is required'),
                // ms_diagnostic_related_group: Yup.string().required(
                //     'MS DRG Code is required'
                // ),
                condition: Yup.string().required(
                    'Condition is required'
                ),
                historical_baseline: Yup.number()
                    .min(0)
                    .max(100)
                    .required('Historical Baseline % is required'),
                cost: Yup.number()
                    .typeError('Must be number')
                    .required('Procedure Cost $ is required'),
                organization: Yup.string()
                    .notRequired()
                    .when('role', {
                        is: (value: string) =>
                            value !== UserRoleEnum.ROLE_ZILIUS_ADMIN,
                        then: Yup.string(),
                        otherwise: Yup.string().required(
                            'Hospital System is required'
                        ),
                    }),
            }),
            onSubmit: async (values) => {
                const finalForm: IAddProcedure = {
                    ...values,
                    facility: `/api/v1/facilities/${values.facility}`,
                    // ms_diagnostic_related_group: `/api/v1/ms-diagnostic-related-groups/${values.ms_diagnostic_related_group}`,
                    condition: `/api/v1/conditions/${values.condition}`,
                    historical_baseline:
                        parseFloat(values.historical_baseline) / 100,
                    cost: parseFloat(values.cost),
                };

                if (isAddMode) {
                    if (await mutateAsync(finalForm)) {
                        push(ROUTES.PROCEDURES);
                    }
                } else {
                    await updateProcedure({
                        ...finalForm,
                        id: ProcedureDetails.id,
                    });
                    push(ROUTES.PROCEDURES);
                }
            },
        });

    const { data: organizationList, isLoading: isOrganizationListLoading } =
        useOrganizations();

    const { data: facilitiesList, isLoading: isFacilitiesListLoading } =
        useFacilities(+values.organization);

    // const { data: MSDRGCodesList, isLoading: isMSDRGCodesListLoading } =
    //     useMSDRGCodes();

    const { data: conditionsList, isLoading: isConditionsListLoading } =
        useConditionsList();

    useEffect(() => {
        if (!isAddMode && ProcedureDetails) {
            setValues({
                organization:
                    ProcedureDetails.facility.organization.id.toString(),
                facility: ProcedureDetails.facility.id.toString(),
                //code: ProcedureDetails.code,
                // ms_diagnostic_related_group: ProcedureDetails.ms_diagnostic_related_group.id.toString(),
                condition:
                    ProcedureDetails.condition.name.toString(),
                historical_baseline: (
                    ProcedureDetails.historical_baseline * 100
                )
                    .toFixed()
                    .toString(),
                cost: ProcedureDetails.cost.toString(),
            });
        }
    }, [ProcedureDetails]);

    useEffect(() => {
        if (facilitiesList || facilitiesList?.items.length) {
            setValues({
                ...values,
                facility: facilitiesList.items[0].id.toString(),
            });
        }
    }, [facilitiesList]);

    return (
        <div>
            <ContentTitle
                title={`${isAddMode ? 'New Hospital Condition' : 'Edit Hospital Condition'}`}
            />

            {!isAddMode &&
            (isOrganizationListLoading ||
                isFacilitiesListLoading ||
                // isMSDRGCodesListLoading ||
                isConditionsListLoading ||
                isDetailsLoading) ? (
                <Loading height={300} />
            ) : (
                <form onSubmit={handleSubmit}>
                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ? (
                        <FormSelect
                            id="organization"
                            label="Hospital System"
                            required
                            handleChange={handleChange}
                            value={values.organization}
                            hasError={
                                touched.organization && errors.organization
                            }
                            errorMsg={errors.organization}
                        >
                            <option value={''} disabled>
                                Please select organization
                            </option>
                            {organizationList?.items.map((organization) => (
                                <option
                                    key={organization.id}
                                    value={organization.id}
                                >
                                    {organization.name}
                                </option>
                            ))}
                        </FormSelect>
                    ) : null}

                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ||
                    user.role ===
                        UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER ? (
                        <FormSelect
                            id="facility"
                            label="Hospital Site"
                            required
                            disabled={
                                user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN
                                    ? values.organization === ''
                                    : false
                            }
                            handleChange={handleChange}
                            value={values.facility}
                            hasError={touched.facility && errors.facility}
                            errorMsg={errors.facility}
                        >
                            <option value={''} disabled>
                                Select facility
                            </option>
                            {facilitiesList?.items.map((facility) => (
                                <option key={facility.id} value={facility.id}>
                                    {facility.name}
                                </option>
                            ))}
                        </FormSelect>
                    ) : null}

                    <FormSelect
                        id="condition"
                        label="Condition"
                        required
                        handleChange={handleChange}
                        value={values.condition}
                        hasError={touched.condition && errors.condition}
                        errorMsg={errors.condition}
                    >
                        <option value={''} disabled>
                            Select condition
                        </option>
                        {conditionsList?.map((condition) => (
                            <option key={condition.id} value={condition.id}>
                                {condition.name}
                            </option>
                        ))}
                    </FormSelect>

                   {/* <MsDrgSelect
                        id="ms_diagnostic_related_group"
                        label="MS DRG Code"
                        required
                        handleChange={handleChange}
                        value={values.condition}
                        hasError={
                            touched.condition &&
                            errors.condition
                        }
                        errorMsg={errors.condition}
                        options={MSDRGCodesList?.items}
                    /> */}
                    {/* <FormInput id="msdrgcode" label="MS DRG Code" required />

                <FormTextArea
                    id="msgdrgdescription"
                    label="MS DRG Description"
                    required
                /> */}

                    <FormInput
                        id="historical_baseline"
                        label="Historical Baseline %"
                        required
                        handleChange={handleChange}
                        value={values.historical_baseline}
                        hasError={
                            touched.historical_baseline &&
                            errors.historical_baseline
                        }
                        errorMsg={errors.historical_baseline}
                    />
                    <FormInput
                        id="cost"
                        label="Procedure Cost $"
                        required
                        handleChange={handleChange}
                        value={values.cost}
                        hasError={touched.cost && errors.cost}
                        errorMsg={errors.cost}
                    />

                    <div className={styles.FormButtons}>
                        <Button
                            title="Submit"
                            fill
                            type="submit"
                            loading={isLoading || isLoadingUpdateProcedure}
                        />
                        <Button title="Cancel" onClick={goBack} />
                    </div>
                </form>
            )}
        </div>
    );
}

export function MsDrgSelect({
    options,
    id,
    label,
    required,
    disabled = false,
    handleChange,
    value,
    hasError,
    errorMsg,
}: any) {
    const reactSelectStyles = {
        control: (base: any) => ({
            ...base,
            fontFamily: "Inter"
        }),
        menu: (base: any) => ({
            ...base,
            fontFamily: "Inter"
        }),
        placeholder: (base: any) => ({
            ...base,
            color: '#3b3b3b',
        })
    }

    const params = options?.map((option: { id: number, code: number; description: string; }) => {
        return {
            name: 'ms_diagnostic_related_group',
            value: option.id,
            label: `${option.code} ${option.description}`,
        }
    })

    const getPlaceholder = (options: any[]) => {
        const placeholder = options?.filter((option) => option.id === value);

        if (Array.isArray(placeholder) && placeholder.length > 0) {
            return `${placeholder[0].code} ${placeholder[0].description}`
        }

        return 'Select code'
    }

    return (
        <FormSelectWithSearch
            name={id}
            handleChange={ (e: any) => handleChange({target: e}) }
            value={value}
            options={params}
            placeholder={getPlaceholder(options)}
            isDisabled={disabled}
            styling={reactSelectStyles}
            label={label}
            required={required}
            hasError={hasError}
            errorMsg={errorMsg}
        />
    );
}
